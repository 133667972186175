.wrapper {
  padding-inline: 90px;
}

@media (max-width: 1200px) {
  .wrapper {
    padding-inline: 50px;
  }
}
@media (max-width: 767px) {
  .wrapper {
    padding-inline: 20px;
  }
}

.view-more_btn {
  border: 3px solid black;
  color: $primary-txt-black;
  transition: 0.3s;
  opacity: 0.6;

  &:hover {
    scale: 1.1;
    opacity: 1;
  }
}

.view-more_btn:hover {
  color: black;
}
.dark-mode .view-more_btn {
  background: linear-gradient(90deg, #37e2d5 -0.29%, #590696 90.18%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  border: 3px solid transparent;
  border-image: $darkmode-bg-gradient;
  border-image-slice: 1;
}
.resume_btn {
  background-color: $primary-txt-black;
  color: $primary-bg-white;
  transition: 0.3s;
  opacity: 0.9;
  &:hover {
    scale: 1.1;
    opacity: 1;
  }
}
.dark-mode .resume_btn {
  background: $darkmode-bg-gradient;
  color: $primary-txt-black;
  font-size: 1.1rem;
}

.dark-mode .resume_btn:hover {
  color: $primary-txt-black;
}

.resume_btn:hover {
  color: white;
}

.resume_btn,
.view-more_btn {
  padding: 13px;
  font-weight: 900;
}

@media (min-width: 767px) {
  .resume_btn,
  .view-more_btn {
    padding: 16px 32px;
    font-weight: 600;
  }
}

.gradient-bar {
  background: linear-gradient(359deg, #37e2d5 -0.29%, #590696 90.18%);
  width: 5px;
  top: 75px;
  z-index: -1;
}

.vertical-text {
  letter-spacing: 1rem;
}

@media (min-width: 767px) {
  .vertical-text {
    writing-mode: vertical-lr;
    text-orientation: upright;
  }
}
@for $i from 1 through 6 {
  .transition-delay-#{$i} {
    transition-delay: ($i * 0.25) + s;
  }
}

// swiper JS
.swiper-slide img {
  width: 100%;
}
.swiper-pagination {
  bottom: -5px !important;
}
.swiper-pagination-bullet {
  width: 16px !important;
  height: 16px !important;
  transition: 0.5s;
}
.swiper-pagination-bullet-active {
  background-color: black !important;
}
.dark-mode .swiper-pagination-bullet {
  background-color: $primary-bg-white;
}
.dark-mode .swiper-pagination-bullet-active {
  background: linear-gradient(90deg, #37e2d5 -0.29%, #590696 90.18%);
}
@media (min-width: 767px) {
  .swiper-slide {
    width: 50% !important;
  }
}
