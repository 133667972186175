@import "./variables";

.hero {
  height: 100vh;
}
@media (max-width: 767px) {
  .hero {
    margin-bottom: 30rem;
  }
}
.hero_title {
  font-size: 2.2rem;
  margin-bottom: 16px;
  transition: 0.5s;
  opacity: 0;
}
@media (min-width: 767px) {
  .hero_title {
    font-size: 3rem;
  }
}
.in-view .hero_title {
  opacity: 1;
}

.hero_title span {
  color: $secondry-txt-grey;
}

.dark-mode .hero_title {
  color: $primary-bg-white;
}
.dark-mode .hero_title span {
  color: $darkmode-txt-grey;
}
.hand-img {
  scale: 0;
  rotate: 60deg;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    rotate: -10deg;
    scale: 1.2;
  }
}
.in-view .hand-img {
  scale: 1;
  rotate: 0deg;
}
.hero_dev {
  opacity: 0;
  transform: translateY(-10px);
  font-size: 3rem;

  color: $primary-txt-black;
  transition: 0.5s;
}
@media (min-width: 950px) {
  .hero_dev {
    font-size: 8rem;
    background: linear-gradient(90deg, #37e2d5 24.11%, #590696 78.6%);
    background-clip: text;
    -webkit-text-stroke: 8px transparent;
  }
}
.in-view .hero_dev {
  opacity: 1;
  transform: translateY(0px);
}

.dark-mode .hero_dev {
  color: $primary-bg-white;
}

.hero_p {
  opacity: 0;
  transform: translateX(-50px);
  font-size: 1.563rem;
  color: $secondry-txt-grey;
  margin-bottom: 28px;
  transition: 0.5s;
}

.in-view .hero_p {
  opacity: 1;

  transform: translateX(0);
}
.dark-mode .hero_p {
  color: $primary-bg-white;
  opacity: 0.7;
}
.hero_links {
  gap: 23px;
  opacity: 0;
  transform: translateY(-50px);
  transition: 0.5s;
}

.in-view .hero_links {
  opacity: 1;
  transform: translate(0px);
}
.dark-mode .hero_icon {
  color: white;
  opacity: 0.7;
}
.dark-mode .hero_icon:hover {
  color: white;
  opacity: 1;
}
.hero_icon:hover {
  transition: 0.5s;
  color: $primary-txt-black;
}
.hero_micheal {
  top: 180px;
  z-index: -1;
}
@media (min-width: 767px) {
  .hero_micheal {
    top: -140px;
  }
}
.hero_code-snippt {
  transition: 0.5s;
  font-size: 1rem;
  color: $secondry-txt-grey;
  min-width: 400px;
  min-height: 100px;
  padding: 16px 24px;
  background: $primary-bg-white;
  right: 50px;
  border-radius: 4px 4px 4px 0px;
}
.dark-mode .hero_code-snippt {
  background-color: $primary-txt-black;
  color: $darkmode-txt-grey;
}
.hero_code-snippt span {
  color: $primary-txt-black;
}

.dark-mode .hero_code-snippt span {
  color: $primary-bg-white;
}
.code-snippt_buttons {
  bottom: -45px;
}
.code-snippt_buttons button {
  background-color: $primary-bg-white;
  padding: 12px 24px;
  color: $secondry-txt-grey;
  font-size: 0.875rem;
  transition: 0.5s;
}

.dark-mode .code-snippt_buttons button {
  background-color: $primary-txt-black;
  color: $darkmode-txt-grey;
}

.code-snippt_buttons .active {
  color: $primary-txt-black;
  border-radius: 0px 0px 0px 8px;
}
.code-snippt_buttons .active:not(:first-child) {
  color: $primary-txt-black;
  border-radius: 0px 0px 8px 0px;
}
.dark-mode .code-snippt_buttons .active {
  background: $darkmode-bg-gradient;
  color: $primary-bg-white;
}

.blue,
.purple {
  top: -20px;
  height: 120%;
  z-index: -2;
  opacity: 0.7;
  filter: blur(40px);
}

@media (min-width: 767px) {
  .blue,
  .purple {
    height: 100%;
  }
}
.blue {
  left: 0;
  background: radial-gradient(
    57.8% 53.24% at 49.94% 50.05%,
    #37e2d5 0%,
    rgba(11, 11, 11, 0) 100%
  );
}
.purple {
  background: radial-gradient(
    71.15% 69.41% at 50% 50%,
    #590696 0%,
    rgba(0, 0, 0, 0) 83.85%
  );
  right: 0;
}

.hero_scroll {
  height: 90px;
  bottom: -290px;
}
@media (min-width: 767px) {
  .hero_scroll {
    bottom: 80px;
  }
}
.hero_scroll span {
  color: $primary-txt-black;
  font-size: 1.2rem;
  height: 90px;
  font-weight: bold;
  position: absolute;
  width: 20px;
  left: 0;
  top: 0;
  transform-origin: bottom center;
}

.dark-mode .hero_scroll span {
  color: #37e2d5;
}
@for $i from 1 through 20 {
  .char#{$i} {
    transform: rotate(($i * 18) + deg);
  }
}

.hero_scroll-icon {
  top: 70%;
  transform: translateX(-15%);
  animation: arrow-scroll 1.5s infinite;
  animation-play-state: paused;
}
.hero_scroll-icon:hover {
  animation-play-state: running;
}
@keyframes arrow-scroll {
  0%,
  10%,
  20%,
  30%,
  50%,
  80%,
  100% {
    transform: translate(-15%, 0);
  }
  40%,
  60% {
    transform: translate(-15%, -20px);
  }
}
.dark-mode .hero_scroll-icon {
  color: #37e2d5;
}
.char_wrapper {
  height: 180px;
  width: 24px;
  animation: rotate 7s forwards infinite linear;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.nav-mobile {
  bottom: -500px;
}
.nav-mobile_item > * {
  color: #555555;
}
.nav-mobile_item.active > * {
  color: black;
}

.dark-mode .nav-mobile_item > * {
  color: rgba(255, 255, 255, 0.6);
}

.dark-mode .nav-mobile_icon {
  color: white;
  opacity: 0.7;
}

.dark-mode .nav-mobile_item.active > * {
  color: white;
  opacity: 1;
  background: $darkmode-bg-gradient;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.dark-mode .active .nav-mobile_icon {
  opacity: 1;
}
