.create-project {
  margin-bottom: 104px;
}
.create-project_title {
  font-size: 2.2rem;
  color: $secondry-txt-grey;
}
@media (min-width: 767px) {
  .create-project_title {
    font-size: 3rem;
  }
}
.dark-mode .create-project_title {
  color: $darkmode-txt-grey;
}
.create-project_title span {
  color: $primary-txt-black;
}
.dark-mode .create-project_title span {
  color: $primary-bg-white;
}
.create-project_p {
  color: $primary-txt-black;
  font-size: 1.2rem;
  max-width: 627px;
  margin-bottom: 64px;
  margin-inline: auto;
}
.dark-mode .create-project_p {
  color: $primary-bg-white;
}

.create-project_skill {
  color: $primary-txt-black;
  opacity: 0.7;
  cursor: pointer;
  transition: 0.3s;
  margin-top: 15px;
  gap: 15px;
}

.skill_icon {
  color: $primary-txt-black;
}

.create-project_skill span {
  font-size: 0.8rem !important;
}

.dark-mode .skill_icon {
  color: $primary-bg-white;
}

.dark-mode .create-project_skill {
  color: $primary-bg-white;
}
.create-project_skill:hover {
  opacity: 1;
}

.dark-mode .create-project p {
  color: white;
}

.dark-mode .create-project img {
  filter: invert(1);
}
@media (min-width: 767px) {
  .create-project_skill {
    margin-bottom: 10px;
    width: 18.5%;
  }

  .create-project_skill .row {
    margin: 0;
  }
}
