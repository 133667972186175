.footer {
  background-color: $primary-txt-black;
  padding-block: 24px;

  & h2 {
    color: $primary-bg-white;
    font-size: 1.8rem;
  }

  & p {
    color: $primary-bg-white;
  }
  &_items {
    gap: 15px;
    font-size: 0.8rem;
  }

  &_contact:hover {
    transition: 0.5s;
    opacity: 1 !important;
  }
}

@media (min-width: 767px) {
  .footer h2 {
    color: $primary-bg-white;
    font-size: 2.5rem;
  }
  .footer_items {
    gap: 20px;
    font-size: 1rem;
  }
}
