.process {
  margin-bottom: 150px;
}

.process span {
  font-size: 1.5rem;
  color: $primary-txt-black;
}

.dark-mode .process span {
  color: $primary-bg-white;
  opacity: 0.6;
}
.process_box {
  gap: 2rem;
  transition: 0.5s;
  opacity: 0;
}
.in-view .process_box {
  opacity: 1;
}
.process_box span {
  color: $primary-txt-black;
  font-size: 3.5rem;
  text-align: center;
}
.dark-mode .process_box span {
  opacity: 1;
  background: linear-gradient(130deg, #37e2d5 -0.29%, #590696 90.18%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.process_box p {
  max-width: 290px;
}
.dark-mode .process_box p {
  color: $primary-bg-white;
}

@media (min-width: 767px) {
  .process {
    padding-left: 150px;
    &_box span {
      width: 50px;
      font-size: 4.5rem;
    }
  }
}
