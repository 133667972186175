@import "./variables";

.header {
  padding-top: 24px;
  margin-bottom: 64px;
}

.header_title {
  font-size: 1.5rem;
  color: $primary-txt-black;
}
@media (min-width: 767px) {
  .header_title {
    font-size: 1.938rem;
  }
}
.dark-mode .header_title {
  color: $primary-bg-white;
}
.nav_links {
  gap: 32px;
}
.nav_link {
  color: $secondry-txt-grey;
  font-weight: 600;
  transition: 0.5s;
  &:hover {
    color: $primary-txt-black;
  }
}
.dark-mode .nav_link {
  color: $primary-bg-white;
}
.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
  cursor: pointer;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $primary-txt-black;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  right: 4px;
  bottom: 4px;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background: $darkmode-bg-gradient;
}

input:checked + .slider:before {
  -webkit-transform: translateX(-21px);
  -ms-transform: translateX(-21px);
  transform: translateX(-21px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.toggle-img {
  left: 4px;
  transition: 0.5s;
}
.dark-mode .toggle-img {
  left: calc(100% - 17px);
}
