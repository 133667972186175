.projects {
  margin-bottom: 100px;
}

.projects_title {
  color: $primary-txt-black;
  font-size: 1.8rem;
  margin-bottom: 2rem;
}

.dark-mode .projects_title {
  color: $primary-bg-white;
}

.projects_box {
  overflow: hidden;
  transition: 0.5s;
  opacity: 0;
}

.in-view .projects_box {
  opacity: 1;
}

.projects_box img {
  transition: 0.5s;
}

.projects_box:hover img {
  transition: 0.5s;
  rotate: 2deg;
  scale: 1.1;
}

.projects_text {
  z-index: 5;
  opacity: 0;
  transition: 0.5s;
}

.projects_box:hover .projects_text {
  opacity: 1;
}

.projects_text a {
  border: 1px solid #6e07f3;
  transition: 0.5s;
}

.projects_box a:hover {
  background-color: #6e07f3;
}

.overlay {
  background-color: #141c3a;
  opacity: 0;
  transition: 0.5s;
  z-index: 1;
}

.projects_box:hover .overlay {
  opacity: 1;
}

@media (min-width: 767px) {
  .projects_title {
    font-size: 3rem;
    margin-bottom: 3rem;
  }

  .projects_box {
    width: 24%;
  }
}

.project-wrap {
  margin-bottom: 3.5rem;

  .project-wrap-header {
    margin-bottom: 1.5rem;
  }
}

.features-wrap {
  max-width: 950px;
  list-style-type: none;
  margin-bottom: 50px;

  li {
    margin-bottom: 10px;
  }
}

.dark-mode .projects h2,
.dark-mode .projects p {
  color: white;
  font-weight: bold;
}

.dark-mode .projects form {
  color: white;
  font-weight: bold;

  & a {
    color: white !important;
  }

  & button {
    background-color: white !important;
    color: black !important;
    font-weight: bold;
  }
}

.project-disclaimer-wrap {
  max-width: 850px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}