.education {
  margin-bottom: 50px;
}

.education_title {
  font-size: 1.8rem;
  max-width: 857px;
  margin-bottom: 90px;
  margin-inline: auto;
  color: $primary-txt-black;
}

.dark-mode .education_title {
  color: $primary-bg-white;
}

.education_title span {
  color: $secondry-txt-grey;
  font-weight: normal;
}

.dark-mode .education_title span {
  color: $darkmode-txt-grey;
}

.experience_box {
  gap: 30px;
  margin-bottom: 80px;
  opacity: 0;
  transform: translateX(-100px);
  transition: 1s;
}

.in-view .experience_box {
  opacity: 1;
  transform: translateX(0px);
}

.education_experience h2 {
  font-size: 1.3rem;
}

.dark-mode .education_experience h2 {
  color: $primary-bg-white;
}

.education_experience p {
  max-width: 500px;
  font-size: 0.8rem;
}

.dark-mode .education_experience p {
  color: $primary-bg-white;
}

.education_experience {
  padding-left: 0rem;
}

.education_experience .gradient-bar {
  height: 0;
  transition: 2s;
  transition-delay: 1s;
  left: 65px;
}

.in-view .gradient-bar {
  height: calc(100% - 240px);
}

.education_experience .purple {
  width: 50%;
  left: 0;
  z-index: -1;
  opacity: 0.24;
  top: 20%;
}

.education_experience .blue {
  width: 50%;
  right: -5%;
  left: unset;
  top: -30%;
}

.education_experience .vertical-text {
  left: 10rem;
}

.education_history .vertical-text {
  top: -2rem;
  left: 5rem;
}

.education_history .gradient-bar {
  right: 65px;
  left: unset;
  height: 15rem;
}

@media (min-width: 767px) {
  .education_history .vertical-text {
    top: 15%;
    right: -15%;
    left: unset;
  }

  .education_title {
    font-size: 3rem;
  }

  .education_experience {
    padding-left: 20rem;
  }

  .education_experience .gradient-bar {
    left: calc(20rem + 65px);
  }

  .education_experience h2 {
    font-size: 2rem;
    max-width: 350px;
  }

  .education_experience p {
    font-size: 1.1rem;
  }

  .education_history {
    padding-left: 5rem;
  }

  .education {
    margin-bottom: 190px;
  }

  .education_history .gradient-bar {
    left: unset;
  }
}

@media (max-width: 767px) {
  .education_experience > p {
    top: -50px !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    right: 0 !important;
  }
}
