@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;600&display=swap");
@import "variables";

// sections
@import "_Header.scss";
@import "_Hero.scss";
@import "create-project";
@import "callme";
@import "education";
@import "projects";
@import "footer";
@import "discover";
@import "process";

// components
@import "./components";

body {
  font-family: "Montserrat", sans-serif;
  background-color: $primary-bg-white;
  transition: 0.5s;
}

body.dark-mode {
  background-color: $primary-txt-black;
}

ul {
  margin: 0;
}
h1 {
  margin: 0;
}

pre {
  overflow: hidden;
  font-size: 0.8rem;
}
