.callme_box {
  background: $darkmode-bg-gradient;
  padding: 64px 32px;
  margin-bottom: 192px;
  border-radius: 1rem;
  gap: 2rem;
}

.callme_title {
  font-size: 1.8rem;
  color: $primary-bg-white;
}
.callme_p {
  max-width: 550px;
  color: $primary-bg-white;
  font-size: 1.2rem;
  line-height: 170%;
}
.callme_title-wrapper {
  gap: 1rem;
}

.callme_box a {
  padding: 16px 32px;
  background-color: $primary-bg-white;
  color: $primary-txt-black;
  margin: auto;
  opacity: 0.8;
  transition: 0.3s;
  &:hover {
    transform: translateY(-5px);
    opacity: 1;
  }
}

@media (min-width: 767px) {
  .callme_box {
    gap: 60px;
    padding: 64px 32px;
    justify-content: space-evenly;
  }
  .callme_box a {
    margin: 0;
  }
}
