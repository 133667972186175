.discover {
  margin-bottom: 59px;

  & h1 {
    margin-bottom: 0.5rem;
    color: $primary-txt-black;
    animation: fade-in 1s forwards;
  }

  & h2 {
    font-size: 1.5rem;
    max-width: 1160px;
    margin: auto;
    color: $primary-txt-black;
    margin-bottom: 2rem;
    animation: fade-in 1s forwards;
  }

  .dark-mode & p {
    color: white;
  }

  .dark-mode & h1,
  .dark-mode & h2 {
    color: $primary-bg-white;
  }

  &_challenge span {
    font-size: 1.5rem;
    color: $primary-txt-black;
  }

  &_challenge p {
    font-size: 1rem;
    color: $primary-txt-black;
    max-width: 950px;
  }

  .dark-mode &_challenge span {
    opacity: 0.6;
  }

  .dark-mode &_challenge span,
  .dark-mode &_challenge p {
    color: $primary-bg-white;
  }
}

.features-wrap {
  transition: 0.3s;
  .dark-mode & {
    color: white;
  }
  .dark-mode & svg {
    color: white;
  }
}
@media (min-width: 767px) {
  .discover {
    margin-bottom: 90px;

    & h2 {
      font-size: 3.5rem;
      margin-bottom: 6rem;
    }

    &_challenge {
      padding-left: 150px;

      & p {
        font-size: 1.5rem;
      }
    }
  }
}

@keyframes fade-in {
  0% {
    transform: translate(-100px, -50px);
    opacity: 0;
  }

  100% {
    transform: translate(0);
    opacity: 1;
  }
}
